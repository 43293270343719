<template>
  <div class="businessBox">
    <div class="businessWrap" v-for="(item, index) in dataList" :key="index">
      <div class="businessTitle clearfloat">
        <div class="fl">
          <h3><span></span> {{item.title}}</h3>
        </div>
        <div class="fr" v-if="item.url && item.url !==''">
          <a class="businessTitleLink" :href="item.url" target="_blank">访问主页</a>
        </div>
      </div>
      <div class="businessInner">
        <div class="pic">
          <img :src="item.img" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataList:[
        {
          title: '融资担保',
          id: '133',
          type: 1,
          img: require('../assets/img/temp/business_img01.jpg'),
          url: 'http://www.gsrzdb.cn/'
        },
        {
          title: '小额贷款',
          id: '135',
          type: 1,
          img: require('../assets/img/temp/business_img03.jpg'),
          url: 'http://www.ghlxd.cn/'
        },
        // {
        //   title: '产权交易',
        //   id: '134',
        //   type: 1,
        //   img: require('../assets/img/temp/business_img02.jpg'),
        //   url: 'http://www.gscq.com.cn/'
        // },
        {
          title: '供应链业务',
          id: '141',
          type: 1,
          img: require('../assets/img/temp/business_img09.jpg'),
          url: ''
        },
        {
          title: '资产管理',
          id: '136',
          type: 1,
          img: require('../assets/img/temp/business_img04.jpg'),
          url: ''
        },
        {
          title: '典当业务',
          id: '137',
          type: 1,
          img: require('../assets/img/temp/business_img05.jpg'),
          url: ''
        },
        {
          title: '私募基金',
          id: '138',
          type: 1,
          img: require('../assets/img/temp/business_img06.jpg'),
          url: ''
        },
        {
          title: '金融仓储',
          id: '139',
          type: 1,
          img: require('../assets/img/temp/business_img07.jpg'),
          url: ''
        },
        {
          title: '融资租赁',
          id: '140',
          type: 1,
          img: require('../assets/img/temp/business_img08.jpg'),
          url: ''
        },
        // {
        //   title: '要素交易',
        //   id: '142',
        //   type: 1,
        //   img: require('../assets/img/temp/business_img10.jpg'),
        //   url: 'http://www.gsrzdb.cn/'
        // },
        {
          title: '商业保理',
          id: '143',
          type: 1,
          img: require('../assets/img/temp/business_img11.jpg'),
          url: ''
        },
      ],
    }
  },
  mounted() {

  },
  methods: {
    
  }
}
</script>