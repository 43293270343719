<template>
  <div class="list">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/temp/banner_business.jpg" alt="">
    </div>
    <div class="listMain wrap">
      <el-row :gutter="30">
        <el-col :span="5">
          <div class="listSlid">
            <div class="listSlidHd">
              <h3>{{slideHd.title}}</h3>
              <p>{{slideHd.sub}}</p>
            </div>
            <div class="listSlidList">
              <div class="listSlidListInner" :class="{on : active == index}" v-for="(item, index) in slideList" :key="index">
                <a :href="`${apiName}business?t=${index}`">{{item.title}}</a>
                <span></span>
              </div>
              <!-- <div class="listSlidListInner">
                <a href="/list?t=1">项目动态</a>
                <span></span>
              </div> -->
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="listWrap">
            <div class="listHd listHdPage clearfloat">
              <div class="fl listHdName">{{hdTitle}}</div>
              <div class="fr listHdGrop"><a :href="`${apiName}`">首页</a> · <a :href="`${apiName}business`">{{slideHd.title}}</a> · <span class="txt">{{hdTitle}}</span> </div>
            </div>
            <div class="listContainer">
              <a v-if="pageLink && pageLink !== ''" class="businessTitleLink" :href="pageLink" target="_blank">访问主页</a>
              <list-business ref="listPage" :gropId="gropId" v-if="pageType == 0"></list-business>
              <detail-page ref="listPage" :gropId="gropId" v-if="pageType == 1"></detail-page>
              <list-page ref="listPage" :gropId="gropId" v-if="pageType == 2"></list-page>
              <list-img ref="listPage" :gropId="gropId" v-if="pageType == 3"></list-img>
              <list-video ref="listPage" :gropId="gropId" v-if="pageType == 4"></list-video>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import listPage from '@/components/list.vue'
import detailPage from '@/components/detail.vue'
import listImg from '@/components/listImg.vue'
import listVideo from '@/components/listVideo.vue'
import listBusiness from '@/components/business.vue'
export default {
  components: {
    listPage,
    detailPage,
    listImg,
    listVideo,
    listBusiness
  },
  name: 'list',
  data(){
    return {
      pageLink: '',
      pageType: 0,
      gropId: '',
      hdTitle: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '公司业务',
        sub: 'BUSINESS',
      },
      slideList:[
        {
          title: '融资担保',
          id: '133',
          type: 1,
          img: require('../assets/img/temp/business_img01.jpg'),
          url: 'http://www.gsrzdb.cn/'
        },
        {
          title: '小额贷款',
          id: '135',
          type: 1,
          img: require('../assets/img/temp/business_img03.jpg'),
          url: 'http://www.ghlxd.cn/'
        },
        // {
        //   title: '产权交易',
        //   id: '134',
        //   type: 1,
        //   img: require('../assets/img/temp/business_img02.jpg'),
        //   url: 'http://www.gscq.com.cn/'
        // },
        {
          title: '供应链业务',
          id: '141',
          type: 1,
          img: require('../assets/img/temp/business_img09.jpg'),
          url: ''
        },
        {
          title: '资产管理',
          id: '136',
          type: 1,
          img: require('../assets/img/temp/business_img04.jpg'),
          url: ''
        },
        {
          title: '典当业务',
          id: '137',
          type: 1,
          img: require('../assets/img/temp/business_img05.jpg'),
          url: ''
        },
        {
          title: '私募基金',
          id: '138',
          type: 1,
          img: require('../assets/img/temp/business_img06.jpg'),
          url: ''
        },
        {
          title: '金融仓储',
          id: '139',
          type: 1,
          img: require('../assets/img/temp/business_img07.jpg'),
          url: ''
        },
        {
          title: '融资租赁',
          id: '140',
          type: 1,
          img: require('../assets/img/temp/business_img08.jpg'),
          url: ''
        },
        // {
        //   title: '要素交易',
        //   id: '142',
        //   type: 1,
        //   img: require('../assets/img/temp/business_img10.jpg'),
        //   url: 'http://cnseedex.com/index.php?lang=cn'
        // },
        {
          title: '商业保理',
          id: '143',
          type: 1,
          img: require('../assets/img/temp/business_img11.jpg'),
          url: ''
        },
      ],
      pageSize: 10,
      pageNum: 1,
      total: 20,
      listData: [
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
      ],
      page: [
        '<p>公司简介</p><p>公司简介</p><p>公司简介</p> <p>公司简介</p><p>公司简介</p>',
        '<p>管理团队</p><p>管理团队</p><p>管理团队</p> <p>管理团队</p><p>管理团队</p>',
        '<p>企业文化</p><p>企业文化</p><p>企业文化</p> <p>企业文化</p><p>企业文化</p>',
      ]
    }
  },
  mounted(){
    var _this = this
    if(_this.$route.query.t){
      _this.active = _this.$route.query.t
      this.$nextTick(() => {
        _this.gropId = _this.slideList[_this.active].id
        _this.pageType = _this.slideList[_this.active].type
      })
      _this.hdTitle = _this.slideList[_this.active].title
      _this.pageLink = _this.slideList[_this.active].url
    }else{
      // _this.active = -1
      // _this.pageType = 0
      this.$nextTick(() => {
        _this.gropId = _this.slideList[0].id
        _this.pageType = _this.slideList[0].type
      })
      _this.hdTitle = _this.slideList[0].title
      _this.pageLink = _this.slideList[0].url
    }
  },
  methods: {
    currentChange(e){
      console.log('e :>> ', e);
    }
  }
}
</script>